import React, { useState, useEffect } from 'react';
import useSharedDashboardData from '../../hooks/useSharedDashboardData';
import { useNavigate } from 'react-router-dom';
import userImg from '../../images/user.png';
import Modal from '../modal/Modal'; // Import your modal component

const Category = () => {
    const { loading, error, cat, refetch } = useSharedDashboardData();
    const [categories, setCategories] = useState([]); // Storing categories in state
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadImage, setUploadImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [validationError, setValidationError] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);

    // Set categories once data is available
    useEffect(() => {
        if (cat && Array.isArray(cat.data)) {
            setCategories(cat.data); // Use cat.data instead of cat
        }
    }, [cat]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openDropdownId && !event.target.closest('.dropdown')) {
                setOpenDropdownId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDropdownId]);

    if (loading) {
        return <div className="loading"><span></span> <span></span> <span></span></div>;
    }
    if (error) {
        return <p>Error loading categories: {error.message}</p>;
    }

    const handleCreateClick = () => {
        setIsEditing(false);
        setTitle('');
        setSelectedImage(null);
        setUploadImage(null);
        setIsModalOpen(true);
    };

    const handleEditClick = (category) => {
        setIsEditing(true);
        setTitle(category.title);
        setSelectedImage(category.image ? `https://phpstack-1252920-4618688.cloudwaysapps.com/${category.image}` : null);
        setUploadImage(null);
        setSelectedCategory(category);
        setIsModalOpen(true); 
    };

    const handleSaveCategory = async () => {
        if (!title) {
            setValidationError('Title is required.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        if (uploadImage) {
            formData.append('image', uploadImage);
        }
        formData.append('isActive', 1);

        const token = localStorage.getItem('authToken');

        const url = isEditing
            ? `https://phpstack-1252920-4618688.cloudwaysapps.com/api/admin/category/${selectedCategory.id}`
            : 'https://phpstack-1252920-4618688.cloudwaysapps.com/api/admin/category';

        const method = isEditing ? 'PUT' : 'POST';

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Authorization': `${token}`
                },
                body: formData,
            });

            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();

            // Update state without page refresh
            setCategories(prevCategories => {
                if (isEditing) {
                    return prevCategories.map(c => (c.id === data.id ? data : c));
                } else {
                    return [...prevCategories, data];
                }
            });
            setIsModalOpen(false);
            setTitle('');
            setSelectedImage(null);
            setUploadImage(null);
            refetch();
            handleCloseModal(); // Close modal after save
        } catch (error) {
            console.error(`${isEditing ? 'Error updating' : 'Error saving'} category:`, error);
            setValidationError('Failed to save category.');
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            setUploadImage(file);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setTitle('');
        setSelectedImage(null);
        setUploadImage(null);
        setValidationError('');
        setIsEditing(false);
    };

    const toggleDropdown = (categoryId) => {
        setOpenDropdownId(prevOpenDropdownId => prevOpenDropdownId === categoryId ? null : categoryId);
    };

    return (
        <>
            <div className="custHome categories notifications">
                <div className="container">
                    <div className="tabs">
                        <button onClick={handleCreateClick} className="createCategory">
                            <i className="fa fa-plus"></i> Create Category
                        </button>
                    </div>
                    <div className="deviceComponent">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Status</th>
                                    <th>Image</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.length > 0 ? (
                                    categories.map((category) => (
                                        <tr key={category.id}>
                                            <td>{category.id}</td>
                                            <td>{category.title}</td>
                                            <td>{category.isActive === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <img
                                                    src={category.image ? `https://backendapi.snapup.life/${category.image}` : userImg}
                                                    alt={category.title}
                                                    width="50"
                                                    height="50"
                                                />
                                            </td>
                                            <td className="text-center">
                                                <div className="dropdown">
                                                    <button className="dropbtn" onClick={() => toggleDropdown(category.id)}>
                                                        <i className="fa-solid fa-ellipsis"></i>
                                                    </button>
                                                    {openDropdownId === category.id && (
                                                        <div className="dropdown-content">
                                                            <button onClick={() => handleEditClick(category)} className="edit_a">Edit</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No categories found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <h2>{isEditing ? 'Update Category' : 'Create New Category'}</h2>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Title"
                />
                <div className="productImage mt-3">
                    <div className="productsImage">
                        <div className="notificationImage">
                            <div className="notificationImageData">
                                <span>Upload image</span>
                                {selectedImage && (
                                    <div className="imagePreview">
                                        <img src={selectedImage} alt="Selected" width="100" height="100" />
                                    </div>
                                )}
                            </div>
                            <div className="notificationFile">
                                <i className="fa fa-edit"></i>
                                <input type="file" name="uploadImage" onChange={handleImageChange} />
                            </div>
                        </div>
                    </div>
                </div>
                {validationError && <p className="error">{validationError}</p>}
                <div className="mFunction">
                    <button onClick={handleSaveCategory}>
                        {isEditing ? 'Update' : 'Save'}
                    </button>
                    <button onClick={handleCloseModal} className="cancelBtn">Cancel</button>
                </div>
            </Modal>
        </>
    );
};

export default Category;
