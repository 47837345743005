import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import useSharedDashboardData from '../../hooks/useSharedDashboardData';
import config from "../../config/config.json";

const AddProduct = () => {
    const [product_name, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [offer_price, setOfferPrice] = useState('');
    const [category, setCategory] = useState(''); 
    const [imagePreview, setImagePreview] = useState('');
    const [uploadImage, setUploadImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [url, setUrl] = useState('');
    const { state } = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();
    const { loading, error, cat, refetch } = useSharedDashboardData();

    useEffect(() => {
        console.log("Fetched categories (cat):", cat);
        if (cat && Array.isArray(cat.data)) {
            setCategories(cat.data); // Set categories to cat.data
        }
    }, [cat]);

    useEffect(() => {
        if (state?.product) {
            const { title, description, price, offer_price, url, category, images } = state.product;
            setProductName(title || '');
            setDescription(description || '');
            setPrice(price || '');
            setOfferPrice(offer_price || '');
            setCategory(category || '');
            setUrl(url || '');

            if (images && images.length > 0) {
                setImagePreview(URL.createObjectURL(images[0]));
                setUploadImage(images[0]);
            }
        }
    }, [state]);

    console.log("setCategories", categories);
    console.log('Selected Category:', category);

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setImagePreview(URL.createObjectURL(selectedFile));
            setUploadImage(selectedFile);
        }
    };

    const handleSaveProduct = async () => {
        const productData = {
            product_name,
            description,
            price,
            offer_price,
            category,  
            url,
        };

        const urlx = state?.product
            ? `${config.API_URL}/admin/product/${id}`
            : `${config.API_URL}/admin/product/`;
        const method = state?.product ? 'PUT' : 'POST';

        const formData = new FormData();
        for (const key in productData) {
            formData.append(key, productData[key]);
        }

        if (uploadImage) {
            formData.append('image', uploadImage);
        }

        console.log('FormData before sending:', Object.fromEntries(formData.entries()));

        try {
            const response = await fetch(urlx, {
                method,
                headers: {
                    'Authorization': `${localStorage.getItem('authToken')}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error saving product:', errorData.message);
                throw new Error(errorData.message);
            }

            const data = await response.json();
            setSuccessMessage('Product added successfully!');
            setTimeout(() => {
                setSuccessMessage(''); // Clear message after 3 seconds
                navigate('/product/'); // Navigate to products
            }, 3000);
            console.log('Product saved successfully:', data); 
        } catch (error) {
            console.error('Failed to save product:', error);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error fetching categories: {error}</p>;

    return (
        <div className="addProduct">
            <div className="productContainer">
                <h2>Add New Product</h2>
                {successMessage && <p className="successMessage">{successMessage}</p>}
                <div className='rowp mt-3'>
                    <div className="pro-lg-6">
                        <div className="formGroup">
                            <div className="pro-lg-6">
                                <p className="label">Category</p>
                                <select
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    className="category-select"
                                >
                                    <option value="">Select category</option>
                                    {categories.map((cat) => (
                                        <option key={cat.id} value={cat.id}>{cat.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mt-1">
                                <p className="label">Title</p>
                                <input
                                    type="text"
                                    value={product_name}
                                    onChange={(e) => setProductName(e.target.value)}
                                    placeholder="Title"
                                />
                            </div>
                            <div className="mt-1">
                                <p className="label">Description</p>
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Description"
                                    rows="5"
                                />
                            </div>
                            <div className="mt-1">
                                <p className="label">URL</p>
                                <input
                                    type="text"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    placeholder="URL"
                                />
                            </div>
                            <div className="row mt-1">
                                <div className="pro-lg-6">
                                    <p className="label">Price</p>
                                    <input
                                        type="text"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        placeholder="Price"
                                    />
                                </div>
                                <div className="pro-lg-6">
                                    <p className="label">Offered Price</p>
                                    <input
                                        type="text"
                                        value={offer_price}
                                        onChange={(e) => setOfferPrice(e.target.value)}
                                        placeholder="Offered Price"
                                    />
                                </div>
                            </div>
                            
                            <div className="mt-1">
                                <div className="pro-lg-6">
                                    <div className="productImage">
                                        <div className="notificationImage">
                                            <div className="notificationImageData">
                                                <span>Upload image</span>
                                                {imagePreview && (
                                                    <div className="imagePreview">
                                                        <img src={imagePreview} alt="Selected" width="100" height="100" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="notificationFile">
                                                <i className="fa fa-edit"></i>
                                                <input
                                                    type="file"
                                                    name="uploadImage"
                                                    onChange={handleImageChange}
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pro-lg-6"></div>
                </div>
                <button onClick={handleSaveProduct} className="addProductSub">
                    {state?.product ? 'Update Product' : 'Add Product'}
                </button>
            </div>
        </div>
    );
};

export default AddProduct;
