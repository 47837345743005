import config from "../config/config.json";

const getToken = () => {
  const token = localStorage.getItem('authToken');
  return token;
};

export const fetchWithToken = async (url) => {
  const token = getToken();
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch');
  }
  return response.json();
};

const fetchData = async () => {
  const token = getToken();
  if (!token) {
    throw new Error('No token found in localStorage');
  }
  const response = await fetch(`${config.API_URL}/admin/dashboard`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  return response.json();
};

export const fetchEmployees = async () => {
  const result = await fetchData();
  return result.totalUser ? result.totalUser.rows : [];
};
export const fetchActiveUser = async () => {
  const result = await fetchData();
  return result;
};
export const fetchUser = async () => {
  const result = await fetchData();
  return result.user;
};

export const fetchUserById = async (userUuid) => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const response = await fetch(`${config.API_URL}/admin/user/${userUuid}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch active user');
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Failed to fetch active user:', error.message);
    throw error;
  }
};

export const deleteUser = async (userUuid) => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const result = await fetch(`${config.API_URL}/admin/user/${userUuid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });
    if (!result.ok) {
      throw new Error('Failed to delete user');
    }
    return result;
  } catch (error) {
    console.error('Failed to delete user:', error.message);
    throw error;
  }
};

export const updateUserImage = async (binaryFile, userUuid) => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const formData = new FormData();
    formData.append('image', binaryFile);
    const result = await fetch(`${config.API_URL}/admin/user/${userUuid}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });
    console.log(result);
    if (!result.ok) {
      throw new Error('Failed to update image');
    }
    const data = await result.json();
    return data.users;
  } catch (error) {
    console.error('Failed to update user image:', error.message);
    throw error;
  }
};


export const updateUserPhone = async (userId, newPhoneNumber) => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const response = await fetch(`${config.API_URL}/${userId}/phone`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
      body: JSON.stringify({ phone: newPhoneNumber }),
    });
    const result = await response.json();
    return result.user;
  } catch (error) {
    console.error('Failed to update phone number:', error.message);
    throw error;
  }
};

export const fetchNotification = async () => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const response = await fetch(`${config.API_URL}/admin/notifications`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Failed to fetch user:', error.message);
    throw error;
  }
};

export const fetchCategory = async () => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const response = await fetch(`${config.API_URL}/admin/category`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Failed to fetch user:', error.message);
    throw error;
  }
}; 

export const fetchProduct = async () => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const response = await fetch(`${config.API_URL}/admin/products`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Failed to fetch user:', error.message);
    throw error;
  }
}; 