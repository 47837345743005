import React, { useState, useEffect } from 'react';
import useSharedDashboardData from '../../hooks/useSharedDashboardData';
import { useNavigate } from 'react-router-dom';
import Modal from '../modal/Modal';
import userImg from '../../images/user.png';
import config from "../../config/config.json";

const Product = () => {
    const { loading, pro, error, refetch } = useSharedDashboardData();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newproducts, setNewproducts] = useState('');
    const [description, setDescription] = useState('');
    const [productsType, setproductsType] = useState('Scheduled');
    const [validationError, setValidationError] = useState('');
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [uploadImage, setUploadImage] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (pro && pro.data) {
            setProducts(pro.data);
        }
    }, [pro]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openDropdownId && !event.target.closest('.dropdown')) {
                setOpenDropdownId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDropdownId]);

    console.log("pro", pro);

    if (loading) {
        return <div className="loading"><span></span> <span></span> <span></span></div>;
    }
    if (error) {
        return <p>Error loading products: {error.message}</p>;
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setNewproducts('');
        setDescription('');
        setproductsType('Scheduled');
        setValidationError('');
        setSelectedImage(null);
        setIsEditing(false);
    };

    const handleSaveproducts = () => {
        if (!newproducts) {
            setValidationError('Title is required.');
            return;
        }
        if (!description) {
            setValidationError('Description is required.');
            return;
        }

        const formData = new FormData();
        formData.append('title', newproducts);
        formData.append('description', description);
        if (uploadImage) {
            formData.append('image', uploadImage);
        }
        formData.append('type', productsType);
        formData.append('isActive', 1);

        const url = isEditing
            ? `${config.BASE_URL}/products/${selectedUser.id}`
            : `${config.BASE_URL}/products`;
        const method = isEditing ? 'PUT' : 'POST';

        fetch(url, {
            method: method,
            headers: { 'Authorization': `${localStorage.getItem('authToken')}` },
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                setProducts(prevproducts => isEditing
                    ? prevproducts.map(n => n.id === data.id ? data : n)
                    : [...prevproducts, data]
                );
                handleCloseModal();
                refetch();
            })
            .catch(error => {
                setValidationError('Failed to save products.');
            });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            setUploadImage(file);
        }
    };

    const toggleDropdown = (userId) => {
        setOpenDropdownId(prevOpenDropdownId => prevOpenDropdownId === userId ? null : userId);
    };

    const filteredpro = products;

    const handleCreateClick = () => {
        setIsEditing(false);
        setNewproducts('');
        setDescription('');
        setproductsType('Scheduled');
        setSelectedImage(null);
        setUploadImage(null);
        setIsModalOpen(true);
    };

    const handleEditClick = (products) => {
        setNewproducts(products.title);
        setDescription(products.description);
        setSelectedImage(products.image ? `${config.API_URL}/img/${products.image}` : null);
        setUploadImage(null);
        setIsEditing(true);
        setIsModalOpen(true);
        setSelectedUser(products);
    };

    const handleEditClickx = (products) => {
        navigate(`/edit/${products.id}`, { state: { products } });
    };

    const handleCreateClickx = () => {
        navigate('/addproduct');
    };

    return (
        <>
            <div className="custHome products notifications">
                <div className="container">
                    <button onClick={handleCreateClickx} className="createProduct"><i className="fa fa-plus"></i> Create Product</button>
                    <div className="deviceComponent">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    <th>Title</th>
                                    <th>Price</th>
                                    <th>Category</th>
                                    <th className="width40">Description</th>
                                    <th>Status</th>
                                    <th>Image</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredpro.map((products, index) => (
                                    <tr key={products.id}>
                                        <td>{index + 1}</td>
                                        <td>{products.product_name}</td>
                                        <td><s>&#8377;{products.price}</s> &#8377;{products.offer_price}</td>
                                        <td>{products.category.title}</td>
                                        <td>{products.description}</td>
                                        <td>{products.is_active === 1 ? "Active" : "Inactive"}</td>
                                        <td>
                                            <img
                                                src={products.image ? `${config.BASE_URL}/${products.image}` : userImg}
                                                alt="product"
                                                width="50"
                                                height="50"
                                            />
                                        </td>
                                        <td className="text-center">
                                            <div className="dropdown">
                                                <button className="dropbtn" onClick={() => toggleDropdown(products.id)}>
                                                    <i className="fa-solid fa-ellipsis"></i>
                                                </button>
                                                {openDropdownId === products.id && (
                                                    <div className="dropdown-content">
                                                        <button onClick={() => handleEditClickx(products)} className="edit_a">Edit</button>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <h2>{isEditing ? 'Update Product' : 'Create Product'}</h2>
                <select value={productsType} onChange={(e) => setproductsType(e.target.value)}>
                    <option value="">Product Category</option>
                    <option value="Instant">Instant</option>
                </select>
                <input
                    type="text"
                    value={newproducts}
                    onChange={(e) => setNewproducts(e.target.value)}
                    placeholder="Title"
                />
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                />
                <div className="row">
                    <div className="col-lg-6">
                        <input
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Price"
                        />
                    </div>
                    <div className="col-lg-6">
                        <input
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Discounted price"
                        />
                    </div>
                </div>

                <div className="productImage mt-3">
                    <div className="productsImage">
                        <div className="productsImage">
                            <div className="productsImageData">
                                <span>Upload image</span>
                                {selectedImage && (
                                    <div className="imagePreview">
                                        <img src={selectedImage} alt="Selected" width="100" height="100" />
                                    </div>
                                )}
                            </div>
                            <div className="productsFile">
                                <i className="fa fa-edit"></i>
                                <input type="file" name="uploadImage" onChange={handleImageChange} />
                            </div>
                        </div>
                    </div>
                    <div className="productsImage">
                        <div className="productsImage">
                            <div className="productsImageData">
                                <span>Upload image</span>
                                {selectedImage && (
                                    <div className="imagePreview">
                                        <img src={selectedImage} alt="Selected" width="100" height="100" />
                                    </div>
                                )}
                            </div>
                            <div className="productsFile">
                                <i className="fa fa-edit"></i>
                                <input type="file" name="uploadImage" onChange={handleImageChange} />
                            </div>
                        </div>
                    </div>
                    <div className="productsImage">
                        <div className="productsImage">
                            <div className="productsImageData">
                                <span>Upload image</span>
                                {selectedImage && (
                                    <div className="imagePreview">
                                        <img src={selectedImage} alt="Selected" width="100" height="100" />
                                    </div>
                                )}
                            </div>
                            <div className="productsFile">
                                <i className="fa fa-edit"></i>
                                <input type="file" name="uploadImage" onChange={handleImageChange} />
                            </div>
                        </div>
                    </div>
                </div>
                {validationError && <p className="error">{validationError}</p>}
                <div className="mFunction">
                    <button onClick={handleSaveproducts}>
                        {isEditing ? 'Update' : 'Save'}
                    </button>
                    <button onClick={handleCloseModal} className="cancelBtn">Cancel</button>
                </div>
            </Modal>
        </>
    );
};

export default Product;
