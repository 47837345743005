import { useState } from 'react';
import user from "../../assets/images/user.png";
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import useSharedDashboardData from '../../hooks/useSharedDashboardData';

function Header() {
  const { userData } = useAuth();
  const { loading, userx, error, setUserx, setError } = useSharedDashboardData();

  const [count, setCount] = useState(0);

  const increment = () => setCount(count + 1);
  const decrement = () => setCount(count - 1);

  return (
    <>
    {/* <div>
      count : {count}
    </div>
    <button onClick={increment}>Increment</button>
    <button onClick={decrement}>Decrement</button>  */}
      <div className="header">
        <div className="headerSearch">
          <h3>Welcome <span>{userData.firstname} {userData.lastname}</span></h3>
        </div>
        <div className="user">
          <div className="notifications">
            <NavLink to="/notifications"><i className="fa fa-bell"></i></NavLink>
          </div>
          <div className="userLink">
            <NavLink to="/settings"><img src={user} alt="" /></NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header; 