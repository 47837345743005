import React, { useState, useEffect } from 'react';
import useSharedDashboardData from '../../hooks/useSharedDashboardData';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import config from "../../config/config.json";

const Edit = () => {
    const { state } = useLocation();
    const { id } = useParams();
    const [product_name, setProductName] = useState('');
    const [category, setCategory] = useState(''); // Store the category ID
    const [url, setUrl] = useState(''); // Store the category ID
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [offer_price, setOfferPrice] = useState('');
    const [image, setImage] = useState('');
    const [previewImage, setPreviewImage] = useState(''); // For previewing new images 
    const [uploadImage, setUploadImage] = useState(null);
    const [hasFetched, setHasFetched] = useState(false);
    const [categories, setCategories] = useState([]); // Store categories
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { loading, error, cat, pro, refetch } = useSharedDashboardData();

    useEffect(() => {
        if (!state?.product && id && !hasFetched) {
            refetch(id);
            setHasFetched(true);
        }
    }, [id, refetch, state?.product, hasFetched]);

    useEffect(() => {
        console.log("Fetched categories (cat):", cat);
        if (cat && Array.isArray(cat.data)) {
            setCategories(cat.data);
        }
    }, [cat]);

    useEffect(() => {
        if (state?.products) {
            const { product_name, description, price, offer_price, image, category, url } = state.products;
            setProductName(product_name || '');
            setDescription(description || '');
            setPrice(price || '');
            setOfferPrice(offer_price || '');
            setImage(image || '');
            setCategory(category.id || ''); // Use category ID here
            setUrl(url || '');
        } else if (pro && !state?.product) {
            const { product_name, description, price, offer_price, image, category, url } = pro;
            setProductName(product_name || '');
            setDescription(description || '');
            setPrice(price || '');
            setOfferPrice(offer_price || '');
            setImage(image || '');
            setCategory(category.id || ''); // Use category ID here
            setUrl(url || '');
        }
    }, [state, pro]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            const imageUrl = URL.createObjectURL(file); // Generate local preview URL
            setPreviewImage(imageUrl); // Set the preview URL
            setImage(fileName); // Store the file name
            setUploadImage(file); // Save the file for backend upload
            console.log("File Name:", fileName);
            console.log("Preview Image URL:", imageUrl);
        }
    };



    const handleSaveProduct = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('product_name', product_name);
        formData.append('description', description);
        formData.append('price', price);
        formData.append('offer_price', offer_price);
        formData.append('category', category);
        formData.append('url', url);

        if (uploadImage) {
            formData.append('image', uploadImage);
        }

        const token = localStorage.getItem('authToken');
        const urlx = `${config.API_URL}/admin/product/${id}`;

        console.log('Submitting form data for product ID:', id);

        try {
            const response = await fetch(urlx, {
                method: 'PUT',
                headers: {
                    'Authorization': ` ${token}`, // Check the authorization format
                },
                body: formData,
            });

            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            console.log('Product updated successfully:', data);
            navigate('/product');
        } catch (error) {
            console.error('Error updating product:', error);
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };


    if (loading) {
        return <p>Loading product data...</p>;
    }

    if (error) {
        return <p>Error loading product data: {error.message}</p>;
    }


    return (
        <div className="addProduct">
            <div className="productContainer">
                <h2>Editing Product - {product_name} {id}</h2>

                <div className='rowp mt-3'>
                    <div className="pro-lg-6">
                        <div className="formGroup">
                            <div>
                                <p className="label">Category</p>
                                <select
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <option value="">Select category</option>
                                    {categories.map(cat => (
                                        <option key={cat.id} value={cat.id}>{cat.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mt-1">
                                <p className="label">Title</p>
                                <input
                                    type="text"
                                    value={product_name}
                                    onChange={(e) => setProductName(e.target.value)}
                                    placeholder="Title"
                                />
                            </div>
                            <div className="mt-1">
                                <p className="label">Description</p>
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Description"
                                    rows="5"
                                />
                            </div>
                            <div className="row mt-1">
                                <div className="pro-lg-6">
                                    <p className="label">Price</p>
                                    <input
                                        type="text"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        placeholder="Price"
                                    />
                                </div>
                                <div className="pro-lg-6">
                                    <p className="label">Offered Price</p>
                                    <input
                                        type="text"
                                        value={offer_price}
                                        onChange={(e) => setOfferPrice(e.target.value)}
                                        placeholder="Discounted Price"
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="productImage mt-3">
                                    <div className="notificationImage">
                                        <div className="notificationImageData">
                                            <span>Upload image</span>
                                            {image && (
                                                <div className="imagePreview">
                                                    {previewImage || image ? (
                                                        <img
                                                            src={previewImage || `${config.BASE_URL}/uploads/${image}`}
                                                            alt="Uploaded Image"
                                                            width="100"
                                                            height="100"
                                                        />

                                                    ) : (
                                                        <p>No image selected</p>
                                                    )}
                                                </div>

                                            )}
                                        </div>
                                        <div className="notificationFile">
                                            <i className="fa fa-edit"></i>
                                            <input
                                                type="file"
                                                name="uploadImage"
                                                onChange={handleImageChange}
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pro-lg-6">
                    </div>
                </div>
                <button onClick={handleSaveProduct} className="addProductSub" disabled={isLoading}>
                    {isLoading ? 'Updating...' : 'Update Product'}
                </button>
            </div>
        </div>
    );
};

export default Edit;
